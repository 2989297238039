import { apiPrefix, formLink } from "@/lib/config";
import { api } from "@Shared/api/createAxiosApi";
import { AxiosError, AxiosResponse } from "axios";
import checkDegrees from "@/lib/services/checks";
import { emptyDictionaryItem, emptyFacultyItem } from "@/lib/_common/formsConditions";
import { captureException } from "@sentry/nextjs";
import { Contestant } from "@Features/OlympiadsForm/model/type/Olympiad.interface";

const baseURL = "/dictionaries";

export interface IDictionaryItem {
  label: string;
  value: string | number | boolean | null;
  items?: any[];
  id?: number;
}

export interface IAdressOption {
  readableTitle: string;
}

export interface IDictionaryIdItem {
  label: string;
  value: number;
}

export interface IDictionaryGroupItem {
  title: string;
  id: number;
}

export interface IFacultyItem {
  label: string;
  id: number;
}

const getPersonIdTypes = async (host: string, citizenship: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/person_id_types/${citizenship}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getDegrees = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/degrees`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (checkDegrees(response.data.result.items) as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getRegions = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/regions`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items as IDictionaryItem[])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

const getAreas = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/areas`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items as IDictionaryItem[])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

const getCities = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/cities`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items as IDictionaryItem[])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

const getLocalities = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/localities`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items as IDictionaryItem[])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

const getStreets = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/streets`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items as IDictionaryItem[])
    .catch((error: AxiosError) => Promise.reject(error.message));
};

const getRelationshipDegrees = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/relationship_degrees`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getCountries = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/countries`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getExamDisciplines = async (
  lang: string,
  _host: string,
  degree: string,
  year: string,
  isSpo?: boolean
): Promise<IDictionaryItem[]> => {
  try {
    const url = `${baseURL}/disciplines_with_programs/${degree}/${year}${typeof isSpo === "boolean" ? `?is_spo=${isSpo}` : ""
    }`;

    const response = await api.get(url, {
      headers: {
        "Accept-Language": lang,
      },
    });
    return response.data.result.items;
  } catch (err) {
    captureException(err);
    return emptyDictionaryItem;
  }
};

const getAllExamDisciplines = async (_host: string, degree: string): Promise<IDictionaryItem[]> => {
  try {
    const url = `${baseURL}/disciplines/${degree}?all=true`;
    const response = await api.get(url);
    return response.data.result.items;
  } catch (err) {
    captureException(err);
    return emptyDictionaryItem;
  }
};

const getMilitaryCategories = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/military_categories`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getPrivileges = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/privileges`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getAdvantages = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/advantages`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getAchievementTypes = async (degree: string, year: number): Promise<IDictionaryItem[]> => {
  try {
    const response = await api.get(`${baseURL}/achievement_types/${degree}/${year}`);
    return response.data.result.items;
  } catch (error) {
    return [];
  }
};

const getAchievements = async (degree: string, year: number): Promise<Achievement[]> => {
  try {
    const response = await api.get(`${baseURL}/achievement_types_with_category/${degree}/${year}`);
    return response.data.result.items;
  } catch {
    return [];
  }
};

const getMarketing = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/itmo_achievement/from_where`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getSpecialConditions = async (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/special_conditions`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getExamTypes = async (
  host: string,
  specializedEducation: boolean,
  belarusians: boolean
): Promise<IDictionaryItem[]> => {
  return api
    .get(
      `${baseURL}/exam_types?has_secondary_specialized_education=${specializedEducation}&for_belarusians=${belarusians}`,
      {
        baseURL: `${host}${apiPrefix}`,
      }
    )
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getOlympiads = async (): Promise<Contestant[] | (typeof emptyDictionaryItem)> => {
  try {
    const response = await api.get(`${baseURL}/olympiad_types`);

    return response.data.result;
  } catch (err) {
    return emptyDictionaryItem;
  }
};

const getConfirmingSubjects = async (params: { olympiadTypeId: string, profile: string, diploma: string, year: string }): Promise<any | (typeof emptyDictionaryItem)> => {
  try {
    const { olympiadTypeId, profile, diploma, year } = params;
    const response = await api.get(`${baseURL}/confirming_subjects/${olympiadTypeId}/${profile}/${diploma}/${year}`);
    return response.data.result;
  } catch (err) {
    return emptyDictionaryItem;
  }
};

const getExamDates = async (
  host: string,
  degree: string,
  examType: string,
  discipline: string
): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/exam_dates/${degree}/${examType}/${discipline}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getDocsWithCitizenship = async (host: string, citizenship: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/person_id_types/${citizenship}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getDocumentTypesRf = async (host: string, degree: string, afterOrBefore: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/education_document_types_rf/${degree}/${afterOrBefore}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getDocumentTypesForeigner = async (host: string, degree: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/education_document_types_foreigner/${degree}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getAdmissionConditions = async (host: string, degree: string, financing: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/admission_conditions/${degree}/${financing}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getFinancing = async (lang: string, host: string, sign: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/financing/${sign}`, {
      baseURL: `${host}${apiPrefix}`,
      headers: {
        "Accept-Language": lang,
      },
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getDirections = async (lang: string, host: string, degree: IDegree): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/directions_on_site/${degree}`, {
      baseURL: `${host}${apiPrefix}`,
      headers: {
        "Accept-Language": lang,
      },
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0
        ? (response.data.result.items as IDictionaryItem[])
        : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getUniversityCities = (host: string, region: string, type: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/university_cities/${region}/${type}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? response.data.result.items : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getUniversityRegions = (host: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/university_regions`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? response.data.result.items : emptyDictionaryItem
    )
    .catch(() => {
      emptyDictionaryItem;
    });
};

const getUniversities = (host: string, city: string, type: string): Promise<IDictionaryItem[]> => {
  return api
    .get(`${baseURL}/universities/${city}/${type}`, {
      baseURL: `${host}${apiPrefix}`,
    })
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? response.data.result.items : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getSpeciality = (): Promise<IDictionaryItem[]> => {
  return api
    .get("dictionaries/speciality_okso")
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? response.data.result.items : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getQualifications = async (degree: IDegree): Promise<IDictionaryItem[]> => {
  return api
    .get(`dictionaries/qualifications/${degree}`)
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.items?.length !== 0 ? response.data.result.items : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getSpecialtyExams = async (programId: number, disciplineId?: number): Promise<IDictionaryItem[]> => {
  const url = disciplineId
    ? `/dictionaries/program/${programId}/specialty_exams?discipline_id=${disciplineId}`
    : `/dictionaries/program/${programId}/specialty_exams`;

  return api
    .get(url)
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getLanguageExams = async (): Promise<IDictionaryItem[]> => {
  return api
    .get("/dictionaries/languages")
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getLanguageDateExams = async (): Promise<IDictionaryItem[]> => {
  return api
    .get("/dictionaries/languages/english/language_exams")
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getFaculties = async (lang: string, degree: string): Promise<IFacultyItem[]> => {
  return api
    .get(`/dictionaries/faculties/${degree}`, {
      headers: {
        "Accept-Language": lang,
      },
    })
    .then((response: AxiosResponse<ISuccessResponse>) => response.data.result.items)
    .catch(() => emptyFacultyItem);
};

const getFacultyPersons = async (facultyId: number, programId: number): Promise<IDictionaryItem[]> => {
  return api
    .get(`/dictionaries/faculty/${facultyId}/program/${programId}/persons`)
    .then((response: AxiosResponse<ISuccessResponse>) =>
      response.data.result?.length !== 0 ? (response.data.result as IDictionaryItem[]) : emptyDictionaryItem
    )
    .catch(() => emptyDictionaryItem);
};

const getApplications = (
  userID: number,
  studentDetailsId: number
): Promise<{ selected_programs: { id: number; scores: string; status: string }[] } | Program> => {
  const url = `${formLink(userID, studentDetailsId)}/application`;
  return api
    .get(url)
    .then((response) => Promise.resolve(response.data.result))
    .catch((error: AxiosError) => Promise.reject(error.response));
};

const getProgramsWithCompetitiveGroup = async (competitive_group_id: string): Promise<IDictionaryItem[]> => {
  const url = `/dictionaries/competitive_groups/${competitive_group_id}/programs`;

  try {
    const response = await api.get(url);
    return response.data.result;
  } catch (err) {
    return emptyDictionaryItem;
  }
};

const getEnterpriseType = async (): Promise<any> => {
  const url = "/dictionaries/enterprise_types";
  try {
    const response = await api.get(url);
    return response.data.result;
  } catch (err) {
    return emptyDictionaryItem;
  }
};

const getUniversityRegionsWindows = async (): Promise<IDictionaryItem[]> => {
  try {
    const url = "/dictionaries/university_regions";

    const { data } = await api.get(url);

    return data.result.items;
  } catch (err) {
    captureException(err);
    return [];
  }
};

const getUniversityCitiesWindows = async (
  region: number,
  type: string
): Promise<IDictionaryItem[] | void> => {
  try {
    const url = `/dictionaries/university_cities/${region}/${type}`;

    const { data } = await api.get(url);

    return data.result.items;
  } catch (err: any) {
    captureException(err);
  }
};

export default {
  getPersonIdTypes,
  getDegrees,
  getRegions,
  getAreas,
  getCities,
  getLocalities,
  getStreets,
  getRelationshipDegrees,
  getCountries,
  getExamDisciplines,
  getExamDates,
  getMilitaryCategories,
  getPrivileges,
  getAdvantages,
  getSpecialConditions,
  getAchievementTypes,
  getAchievements,
  getMarketing,
  getExamTypes,
  getAllExamDisciplines,
  getDocumentTypesRf,
  getDocumentTypesForeigner,
  getDocsWithCitizenship,
  getAdmissionConditions,
  getFinancing,
  getUniversityCities,
  getUniversityRegions,
  getUniversities,
  getSpeciality,
  getQualifications,
  getDirections,
  getSpecialtyExams,
  getLanguageExams,
  getLanguageDateExams,
  getFaculties,
  getFacultyPersons,
  getApplications,
  getProgramsWithCompetitiveGroup,
  getEnterpriseType,
  getUniversityRegionsWindows,
  getUniversityCitiesWindows,
  getOlympiads,
  getConfirmingSubjects,
};
