import { IDictionaryItem } from "@/lib/services/dictionary.service";

const allowedDegrees = [
  "bachelor",
  "master",
  "postgraduate",
];

const checkDegrees = (data : IDictionaryItem[]): IDictionaryItem[] | undefined => {
  return data.filter((item) => allowedDegrees.includes(<string>item.value));
};

export default checkDegrees;
